import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as loginActions from '../../store/modules/loginActions';
import * as messageActions from '../../store/modules/messageActions';
import { bindActionCreators } from 'redux';
import EmailVerificationPanel from './EmailVerificationPanel';


const PwChangePanel = () => {
    const LoginStates = useSelector((state) => state.loginActions);
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);
    const MessageActions = bindActionCreators(messageActions, dispatch);

    const [userInfo, setUserInfo] = useState({
            userId: LoginStates.get('userId'),
            userName: LoginStates.get('userName'),
            userPassword: '',
            newPassword: '',
            newPasswordCheck: '',
            newPwCheck: 'default',
            reNewPwCheck: 'default',
            isVerify: false
        });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newPassword = userInfo.newPassword;
        let newPasswordCheck = userInfo.newPasswordCheck;

        const upperLetters = /[A-Z]/;
        const lowerLetters = /[a-z]/;
        const number = /[0-9]/;
        const special = /[~!@#$%^&*()_+|<>?:{}]/;

        setUserInfo(prev => { return { ...prev, [name]: value }});   //userInfo값이 업데이트되는 기준은 이벤트가 끝까지 돌고 나서

        if (name == 'newPassword')
            newPassword = value;
        else if (name == 'newPasswordCheck')
            newPasswordCheck = value;

        //새 비밀번호 체크
        if (newPassword.length == 0) {
            newPasswordCheck = '';
            setUserInfo(prev => { return { 
                ...prev, 
                newPasswordCheck: '',
                newPwCheck: 'default'
            }});
        }
        else if (newPassword.length >= 7 && upperLetters.test(newPassword) && lowerLetters.test(newPassword) &&
            special.test(newPassword) && number.test(newPassword))
            setUserInfo(prev => { return { ...prev, newPwCheck: 'right' }});
        else
            setUserInfo(prev => { return { ...prev, newPwCheck: 'wrong' }});

        //새 비밀번호 다시 체크
        if (newPasswordCheck.length == 0)
            setUserInfo(prev => { return { ...prev, reNewPwCheck: 'default' }});
        else if (newPasswordCheck == newPassword)
            setUserInfo(prev => { return { ...prev, reNewPwCheck: 'right' }});
        else if (newPasswordCheck != newPassword)
            setUserInfo(prev => { return { ...prev, reNewPwCheck: 'wrong' }});
    };

    const PwClass = (num) => {
        if (num == 'default')
            return (
                <div className=''>
                    <i className=''/>
                </div>
            );
        else if (num == 'wrong')
            return (
                <div className='ico-check wrong'><i className='i-input-wrong' /></div>
            );
        else if (num == 'right')
            return (
                <div className='ico-check'><i className='i-input-ok' /></div>
            );
    };

    const ConditionPw = (num) => {
        if (num == 'default' || num == 'right')
            return (
                <p />
            );
        else
            return (
                <div className="pw-msg">비밀번호는 대문자, 소문자, 숫자, 특수문자 조합으로 7자 이상이어야 합니다.</div>
            );
    };

    const ChangePwClass = () => {
        if (userInfo.newPwCheck == 'right' && userInfo.reNewPwCheck == 'right' && userInfo.userPassword != '')
            return (
                <button onClick={onSubmitPwChange} className='btn-primary'>비밀번호 변경</button>
            );
        else
            return (
                <button className='btn-primary btn-dis'>비밀번호 변경</button>
            );
    };

    const onSubmitPwChange = (e) => {
        e.preventDefault();
        const passwordsInfo = {
            userId: encodeURI(userInfo.userId),
            userPassword: encodeURI(userInfo.userPassword),
            newPassword: encodeURI(userInfo.newPassword)
        };

        fetch('api/Login/PasswordChange', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(passwordsInfo)
        }).then(async (response) => response.json())
            .then(async (responseData) => {
                if (responseData.status == "Wrong Current Password") {
                    showToast('danger', responseData.message, 3000);
                    setUserInfo(prev => { return { ...prev, currentPassword: '' }});
                }
                else if (responseData.status == "Success Changing Password") {
                    const info = responseData.userInformation;
                    const result = {
                        panelType: '',
                        userId: info != undefined ? info.userId : '',
                        userName: info != undefined ? info.userName : '',
                        message: responseData.message,
                        result: responseData.status,
                        sessionId: info != undefined ? info.sessionID : '',
                        logindate: new Date().toISOString().slice(0, 10)
                    };
                    await LoginActions.setResultLogin(result);
                }
            });
    };

    // const GoBackLogin = () => {
    //     LoginActions.panelLoad('loginAgain');
    // };

    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);
    };

    const AfterCheckAuth = () => {
        setUserInfo(prev => { return { 
            ...prev,
            isVerify: true
        }});
    };

    const renderPanel = () => {
        if (userInfo.isVerify) {
            return (
                <>
                    <p className="pw-change-txt">안전한 사용을 위해 비밀번호를 변경해주세요. </p>
                    <div className="login-input">
                        <input
                            type='password'
                            name='userPassword'
                            value={userInfo.userPassword}
                            onChange={e => handleChange(e)}
                            placeholder='현재 비밀번호'
                        />
                    </div>
                    <div className="login-input">
                        <input
                            type='password'
                            name='newPassword'
                            value={userInfo.newPassword}
                            onChange={e => handleChange(e)}
                            placeholder='새 비밀번호(대문자, 소문자, 숫자, 특수문자 조합으로 7자 이상)'
                        />
                        {PwClass(userInfo.newPwCheck)}
                        {ConditionPw(userInfo.newPwCheck)}
                    </div>
                    <div className="login-input">
                        <input
                            type='password'
                            name='newPasswordCheck'
                            value={userInfo.newPasswordCheck}
                            onChange={e => handleChange(e)}
                            placeholder='새 비밀번호 확인'
                        />
                        {PwClass(userInfo.reNewPwCheck)}
                    </div>

                    <div className="btn-wrap">
                        {ChangePwClass()}
                        {/*<button onClick={GoBackLogin} className='btn-back'>로그인화면으로 돌아가기</button>*/}
                    </div>

                    <div className="info-box-kstudio">
                        K Developers의 계정으로 영림원소프트랩의 개발 툴인 K-Studio를 사용할 수 있습니다.
                        <br /><br /><ol>
                            <li>K-Studio는 라이선스를 구매한 고객사에게만 제공되며, 라이선스를 보유하지 않은 사용자는 사용할 수 없습니다.</li>
                            <li>K-Studio로 생성된 모든 산출물은 라이선스를 구매한 고객사 내부에서만 사용이 허용됩니다.</li>
                            <li> K-Studio로 생성한 산출물을 불법적으로 복제, 배포, 판매하는 것은 엄격히 금지됩니다.</li>
                        </ol>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <p className="pw-change-txt">비밀번호 유효기간(90일)이 지나 비밀번호 변경이 필요해요.<br />먼저, 본인확인을 위해 이메일 인증을 해주세요.</p>
                    <EmailVerificationPanel buttonTitle={'이메일로 인증번호 받기'} state={userInfo} fnAfterCheckAuth={AfterCheckAuth} />
                </>
            );
        }
    }

    return (
        <div className="login-wrap pw-change">
            <form className="login-box">
                <h2>비밀번호 변경</h2>
                {renderPanel()}
            </form>
        </div>
    );
};

export default PwChangePanel;