import { Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const CHANGEPASSWORD = 'loginActions/CHANGEPASSWORD';
const SIGNUP = 'loginActions/SIGNUP';
const SHOWMESSAGE = 'loginActions/SHOWMESSAGE';
const FINDPASSWORD = 'loginActions/FINDPASSWORD';
const RESULT_LOGIN = 'loginActions/RESULT_LOGIN';
const PANELLOAD = 'loginActions/PANELLOAD';
const SET_STATES = 'loginActions/SET_STATES';
const INIT_STATES = 'loginActions/INIT_STATES';

export const changePassword = createAction(CHANGEPASSWORD);
export const signUp = createAction(SIGNUP);
export const showMessage = createAction(SHOWMESSAGE);
export const findPassword = createAction(FINDPASSWORD);
export const setResultLogin = createAction(RESULT_LOGIN);
export const panelLoad = createAction(PANELLOAD, panel => panel);
export const setLoginInfo = createAction(SET_STATES);
export const init = createAction(INIT_STATES);


const initialState = Map({
    panelType: '',
    userId: '',
    userPassword: '',
    userName: '',
    message: '',
    result: '',
    sessionId: '',
    logindate: '', 
    companyName: '',
    admin:'',
    companySeq: '',
    companyType: '',
    devType: '',
    userPhoneNum: '',
    userSendOtpType: '',
    deptName: '',
    kindName: ''
});

export default handleActions({
    [CHANGEPASSWORD]: (state, action) => {
        return state.set('panelType','changePassword');
    },
    [SIGNUP]: (state, action) => { 
        return state.set('panelType', 'signUp');
    },
    [SHOWMESSAGE]: (state, action) => {
        return state.set('panelType', 'showMessage');
    },
    [FINDPASSWORD]: (state, action) => {    
        return state.set('panelType', 'findPassword');
    },
    [RESULT_LOGIN]: (state, action) => {
        return state.merge(action.payload);
    },
    [PANELLOAD]: (state, { payload: panel }) => {
        return state.set('result', '')
            .set('panelType', panel);
    },
    [SET_STATES]: (state, action) => {
        //state = action.payload;
        return state.merge(action.payload);
    },
    [INIT_STATES]: (state, action) => {
        //state = action.payload;
        return state.merge(initialState);
    }
}, initialState);