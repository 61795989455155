import React, { useEffect, useState } from 'react';
import MenuFilter from './Common/MenuFilter';
import MenuTree from './libs/left-menu-tree';
import ContentViewer from './Common/ContentViewer';
import ScrollIntoView from './Common/ScrollIntoView';
import Location from './Common/Location';
import PDFLink from './Common/PDFLink';
import DocumentLink from './Common/DocumentLink';

import './styles/themes/vs.css';

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contentActions from '../store/modules/contentActions';
import { useLocation, useNavigate } from 'react-router-dom';
import storage from './libs/storage';

const Document = (props) => {
    const LoginStates = useSelector((state) => state.loginActions);
    const ContentStates = useSelector((state) => state.contentActions);
    const dispatch = useDispatch();
    const ContentActions = bindActionCreators(contentActions, dispatch);
    const location = useLocation();
    const history = useNavigate();
    const loginStates = storage.get('loginStates');

    let listName = location.pathname.replace('/', '');      

    const [path, setPath] = useState(listName);
    const [menuMode, setMenuMode] = useState('left');
    const [active, setActive] = useState();
    const [contentInfo, setContentInfo] = useState({
        contentType: '',
        contentName: '',
        folderName: '',
        type: '',
        docNo: '',
        url: ''
    });
    const from = location.state || '';

    let subject = '', nodepath = location?.state == 'Favorite' || 'DocumentLink' ? ContentStates.get('nodepath') : '';
    if (ContentStates.get('menuViews') != null && ContentStates.get('menuViews') != undefined) {
        subject = ContentStates.get('menuViews').module;
    }
    let tabName = ContentStates.get('tabName') != undefined ? ContentStates.get('tabName') : '';
    let nodePath = ContentStates.get('nodepath') != '' ? tabName + '/' + ContentStates.get('nodepath') : tabName;

    useEffect(() => {
        // console.log(location.pathname);
        // location.pathname.replace('/', '');
    }, [])

    useEffect(() => {
        updateMenu(listName, from);
    }, [listName]);

    const updateMenu = (listName, from = '') => {
        let list = {};
        let contentName = from === '' ? from : ContentStates.get('contentName');
        let path = from === '' ? from : ContentStates.get('nodepath');
        let type = from === '' ? from : ContentStates.get('docType');
        let folderName = from === '' ? from : ContentStates.get('folderName');
        let url = from === '' ? from : ContentStates.get('url');
        let video = from === '' ? from : ContentStates.get('video');
        fetch('datas/' + listName + '.json',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Cache-Control': 'no-cache'
                }
            }).then((resp) => resp.json()).then(data => {
                list = data;

                // console.log(data);

                ContentActions.setMenu({ treeData: list, treeView: list });
                ContentActions.setContentInfo({
                    contentType: listName,
                    contentName: contentName || list.default,
                    folderName: folderName,
                    type: type,
                    tabName: data.module,
                    url: url,
                    video: video
                });                
                setContentInfo({
                    contentType: listName,
                    contentName: contentName || list.default,
                    folderName: folderName,
                    type: type,
                    url: url,
                    video: video
                });
                
            }).catch(e => {
                history("/");
            });
        if (contentName == '') {
            ContentActions.setPath('');
            // path = '';
            // type = '';
        } else {
            ContentActions.setPath(path);
        }
    };
    
    const onClickNode = node => {
        let url = '';
        let type = 'pdf';
        if (node.item.opentab != undefined) {
            const userInfo = {
                "userId": LoginStates.get('userId'),
                "sessionId": LoginStates.get('sessionId')
            };
            if (node.item.opentab.SSOID != undefined && node.item.opentab.SSOID) {
                fetch('api/User/GetNewSSOId', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    body: JSON.stringify(userInfo)
                }).then((response) => response.json())
                    .then(resultData => {
                        if (resultData.table[0].status == "0") {
                            const url = node.item.opentab.url;
                            window.open("", node.item.module);
                            let tabForm = document.createElement('form');
                            tabForm.name = 'tabForm';
                            tabForm.method = node.item.opentab.method;
                            tabForm.action = url;
                            tabForm.target = node.item.module;
                            node.item.opentab.data.forEach(function (data) {
                                let input = document.createElement('input');
                                input.setAttribute("type", "hidden");
                                input.setAttribute("name", data.key);
                                if (data.value == 'SSOId') {
                                    input.setAttribute("value", resultData.table[0].ssoid);
                                }
                                else {
                                    input.setAttribute("value", data.value);
                                }
                                tabForm.appendChild(input);
                            });
                            document.body.appendChild(tabForm);
                            tabForm.submit();
                            document.getElementById("tabForm");
                        }
                        else {

                        }
                    });
            } else {
                const url = node.item.opentab.url;
                window.open("", node.item.module);
                let tabForm = document.createElement('form');
                tabForm.name = 'tabForm';
                tabForm.method = node.item.opentab.method;
                tabForm.action = url;
                tabForm.target = node.item.module;
                node.item.opentab.data.forEach(function (data) {
                    let input = document.createElement('input');
                    input.setAttribute("type", "hidden");
                    input.setAttribute("name", data.key);
                    input.setAttribute("value", data.value);
                    tabForm.appendChild(input);
                });
                document.body.appendChild(tabForm);
                tabForm.submit();
                document.getElementById("tabForm");
            }
        }

        if (node.item.type == undefined || node.item.type != 'pdf')
            type = 'markdown';
        if (node.item.type == 'notion') {
            type = 'notion';
            url = node.item.url;
        }
        let contentName = node.name;
        let contentType = contentInfo.contentType;
        if (node.item.markdown != undefined) {
            contentName = node.item;
        }
        let folderName = "";
        let path = node.path;
        let tabName = ContentStates.get('tabName');
         if (node.item.hasOwnProperty('directory')) {
             let directory = node.item.directory;
             
             if (directory == 'path') {                 
                 if (node.item.filterPath != undefined)
                     path = node.item.filterPath;
                 folderName = path.replace("/" + node.name, "");
             }
            else
                folderName = directory;
        }
        let video = "";
        if (node.item.hasOwnProperty('video')) {
            video = node.item.video;
        }
        if (menuMode == 'left') {
            setContentInfo({
                contentType: contentType,
                contentName: contentName,
                folderName: folderName,
                type: type,
                docNo: node.docNo,
                url: url,
                video:video
            });
            setActive(node);
            setMenuMode('left open');
        } else {
            setContentInfo({
                contentType: contentType,
                contentName: contentName,
                folderName: folderName,
                type: type,
                docNo: node.docNo,
                url: url,
                video:video
            });
            setActive(node);
            setMenuMode('left');
        }
        ContentActions.setContentInfo({
            contentType: contentType,
            contentName: contentName,
            folderName: folderName,
            type: type,
            tabName: tabName,
            docNo: node.docNo,
            url: url,
            video:video
        });
        ContentActions.setPath(path);
    };

    const contentViewerCall = (nodePath) => {
        if (contentInfo.contentType == '')
            return '';
        else
            return (
                <ContentViewer ContentInfo={contentInfo} NodePath={nodePath} />
            );
    };

    const loadRightContent = () => {
        if (contentInfo.type == 'pdf' || contentInfo.type == 'notion')
            return (
                <div className="page-index">
                </div>
            );
        else
            return (<ScrollIntoView />);
    };

    const Content = contentViewerCall(nodePath);
    const rightContent = loadRightContent();

    const menuOpen = () => {
        if (menuMode == 'left') {
            setMenuMode('left open');
        } else {
            setMenuMode('left');
        }
    };

    // if ((listName === "DM" && LoginStates?.get('userId').indexOf('@ksystem.co.kr') == -1) || (listName === "Learn" && LoginStates.get('companyType') !== "9")) {
    if ((listName === "DM" || listName === "Learn") && (LoginStates.get('companyType') !== "9") && LoginStates.get('companySeq') !== "128") {
        history("/");
    } else {
        return (
            <div className="container doc-type">
                <div className="contents-wrap">
                    <div className={menuMode}>
                        <h3 className="narrow-title"><a onClick={menuOpen}>{subject}<i className="i-menu-dropdown"></i></a></h3>
                        <h3 className="tree-title"><a onClick={() => updateMenu(listName)}>{subject}</a></h3>
                        <MenuFilter />
                        <div className="left-menu">
                            <MenuTree data={ContentStates.get('menuViews')} onClickNode={onClickNode} nodepath={nodepath} contentType={ContentStates.get('contentType')} />
                        </div>
                    </div>
                    <div className="content">
                        <div className="doc-wrap">
                            <PDFLink />
                            <DocumentLink path={nodePath} />
                            <Location path={nodePath} />
                            {Content}
                        </div>
                    </div>
                    {rightContent}
                </div>
            </div>
        );
    }
};

export default Document;