import React, { Fragment, useEffect, useState } from 'react';
import UserInfo from './AccountPanels/UserInfo';
import TabMenu from './Common/TabMenu';
import Topic from './ManagerPanels/Topic';
import UserInfoAcc from './AccountPanels/UserInfoAcc';
import AuthRequestList from './AccountPanels/AuthAccountPanels/AuthRequestList'
import storage from './libs/storage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthConfirm from './AccountPanels/AuthConfirm';

const Account = () => {
    const loginStates = storage.get('loginStates');
    const companySeq = loginStates?.get('companySeq');
    const location = useLocation();
    const history = useNavigate();
    const LoginStates = useSelector(state => state.loginActions);

    const [tabInfo, setTabInfo] = useState({
        current: '',
        menu: [
            { id: "userinfo", title: "내 정보", type: "tab" },
            { id: "topicList", title: "1:1문의", type: "tab" },
            { id: "userinfoAcc", title: "개발 권한", type: "tab" },
        ],
        showPopup: false,
        isUseAuth: 0,
        checkReq: false,
    });
    const [tabContent, setTabContent] = useState();

    useEffect(() => {
        getAuth();
        checkAdmin();
    }, []);

    const hadleCurrentPopup = (mode) => {
        setTabInfo(prev => { 
            return { 
                ...prev,
                current: mode
            }
        });
    };

    const getAuth = () => {
        //권한 보유중인 사용자만 보이도록
        const companyList = ["128", "129"];

        if (companyList.includes(companySeq))
            setTabInfo(prev => {
                return { 
                    ...prev, 
                    isUseAuth: 1,
                    menu: [
                        ...prev.menu, 
                        { 
                            id: "authAcc", 
                            title: "신청 내역", 
                            type: "tab" 
                        }
                    ] 
                }
            });
    };

    //관리자 권한 체크하여 메뉴 표시
    const checkAdmin = () => {
        let auth = LoginStates?.get('admin');
        if (auth == undefined || auth == '') {
            auth = loginStates?.get('admin');
        }
        let splauth = auth.split(",");
        if (splauth.includes("GroupAdmin") || splauth.includes("DevUserAdmin")) {
            setTabInfo(prev => {
                return { 
                    ...prev, 
                    menu: [
                        ...prev.menu, 
                        { 
                            id: "authConfirm", 
                            title: "권한신청관리", 
                            type: "tab" 
                        }
                    ] 
                }
            });
        }
    };

    //권한 신청 팝업
    const handleRequestPopup = (flag) => {
        setTabInfo(prev => { return { ...prev, showPopup: flag }});
    };

    const loadTabContent = () => {
        if (tabInfo.current == 'authAcc') {
            if (tabInfo.checkReq == false)
                return (<AuthRequestList />);
        }
        else if (tabInfo.current == 'authConfirm')
            return (
                <AuthConfirm />
            );
        else if (tabInfo.current == 'userinfoAcc')
            return (
                <UserInfoAcc isUseAuth={tabInfo.isUseAuth} />
            );
        else if (tabInfo.current == 'topicList' || location.state?.current != undefined)
            return (
                <Topic ClickMenu={hadleCurrentPopup} current={location.state?.current != undefined && tabInfo.current == '' ? location.state?.current : tabInfo.current} isAdmin={false} nodePath={location.state?.nodePath} catagoryName={location.state?.catagoryName} locationFrom={location.state?.locationFrom} />
            );
        else
            return (
                <UserInfo />
            );
    };

    useEffect(() => {
        const content = loadTabContent();
        setTabContent(content);
    }, [tabInfo]);

    const [buttons, setButtons] = useState([]);
    useEffect(() => {
        let buttonList = [];
        const menu = tabInfo.menu;
        if (tabInfo.isUseAuth == 1) {
            if (menu.length > 0) {
                const m_buttons = menu.filter(x => x.type == "button")
                buttonList.push(
                    m_buttons.map((x, i) => (
                        <button className={x.style} onClick={() => { handleRequestPopup(true) }} >{x.title}</button>
                    ))
                );
            }
        }
        setButtons(buttonList);
    }, []);

    if(loginStates)
        return (
            <Fragment>
                <div className="container">
                    <div className="setting-wrap">
                        <TabMenu ClickMenu={hadleCurrentPopup} menuDatas={tabInfo.menu} current={location.state?.current != undefined && tabInfo.current == '' ? location.state?.current : tabInfo.current} title={location.state?.userId} buttons={buttons} />
                        {tabContent}
                    </div>
                </div>
            </Fragment>
        );
    else
        return (
            history("/")
        );
}

export default Account;