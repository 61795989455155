import Home from "./components/Home";
import Login from "./components/Login";
import Account from "./components/Account";
import Admin from "./components/Admin";
import ContentEditor from "./components/ManagerPanels/ContentEditor";
import KdcOpen from "./components/KdcOpen";
import Search from "./components/Search";


const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: "/Login",
    element: <Login />
  },
  {
    path: '/Login/:panel',
    element: <Login />
  },
  {
    path: '/MyPage',
    element: <Account />
  },
  {
    path: '/Manager',
    element: <Admin />
  },
  {
    path: '/Manager/:admin',
    element: <Admin />
  },
  {
    path: '/ContentEdit',
    element: <ContentEditor />
  },
  {
    path: '/KdcOpen',
    element: <KdcOpen />
  },
  {
    path: "/Search",
    element: <Search />
  }
];

export default AppRoutes;
